$( document ).ready(function() {
    console.log( "ready!" );
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="tooltip"]').tooltip()
    //AOS.init();
     // When the window resizes
     $(window).on('resize', function () {
        var mastHeight = $('header').outerHeight();

       // Add the height to `.site-content`
       $('body').css('margin-top', mastHeight); 
       $('.place-top').css('margin-top', - mastHeight); 
   });
   $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
      $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
  });
    // Trigger the function on document load.
   $(window).trigger('resize');
});